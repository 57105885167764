import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GetState, Subscribe } from '../state/createState';
import { DailyAgendaState } from '../state/types';
import { ControllerProps } from '../controller';
import { WidgetViewModel } from './viewModel';
import { subscribeToAgendaViewModel } from './agendaViewModel/agendaViewModel';
import { subscribeToTimeZoneViewModel } from './timeZoneViewModel/timeZoneViewModel';

export type SetViewModel = (partialViewModel: Partial<WidgetViewModel>) => void;
export type ViewModelSubscription = (args: {
  subscribe: Subscribe<DailyAgendaState>;
  setViewModel: SetViewModel;
  flowAPI: ControllerFlowAPI;
  getState: GetState;
}) => void;

export const initViewModelSubscriptions = ({
  flowAPI,
  getState,
  viewModel,
  subscribe,
  setProps,
}: {
  flowAPI: ControllerFlowAPI;
  getState: GetState;
  viewModel: WidgetViewModel;
  subscribe: Subscribe<DailyAgendaState>;
  setProps: (props: ControllerProps) => void;
}) => {
  const setViewModel: SetViewModel = (partialViewModel) => {
    Object.assign(viewModel, partialViewModel);
    setProps({ viewModel: { ...viewModel } });
  };

  const args = {
    subscribe,
    setViewModel,
    flowAPI,
    getState,
  };

  subscribeToAgendaViewModel(args);
  subscribeToTimeZoneViewModel(args);
};

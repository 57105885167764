import {
  createSettingsParams,
  ISettingsParam,
  PartialBy,
  SettingsParamType,
} from '@wix/tpa-settings';
import { SlotsAvailability } from '../../types/types';
import { AccessibilityHtmlTags } from '../../utils/a11y/consts';

export type ISettingsParams = {
  timeSlotsDisplay: SlotsAvailability;
  limitTimeSlots: SettingsParamType.Boolean;
  maxTimeSlotsPerDay: SettingsParamType.Number;
  scrollBetweenDays: SettingsParamType.Boolean;
  itemsBeforeLoadMore: SettingsParamType.Number;
  selectedLocations: SettingsParamType.Text[];
  selectedCategories: SettingsParamType.Text[];
  titleText: SettingsParamType.Text;
  titleTagName: SettingsParamType.String;
  selectedServices: SettingsParamType.String[];
  timeSlotsPerDay: SettingsParamType.Number;
  spotsLeftVisibility: SettingsParamType.Boolean;
  noSpotsLeft: SettingsParamType.Text;
  spotsLeftFormat: SettingsParamType.Text;
  serviceLabel: SettingsParamType.Text;
  staffMemberLabel: SettingsParamType.Text;
  locationLabel: SettingsParamType.Text;
  timeSlotsBeforeLoadMore: SettingsParamType.Number;
  toEarlyToBookIndication: SettingsParamType.Text;
  toLateToBookIndication: SettingsParamType.Text;
  bookOnlineDisabledIndication: SettingsParamType.Text;
  joinWaitlistButton: SettingsParamType.Text;
  pendingApprovalButton: SettingsParamType.Text;
  bookButtonText: SettingsParamType.Text;
};

export type ISettingsParamsDefinitions = {
  [key in keyof ISettingsParams]: PartialBy<
    ISettingsParam<ISettingsParams[key]>,
    'key'
  >;
};

export const settingsParamsDefinitions: ISettingsParamsDefinitions = {
  timeSlotsDisplay: {
    getDefaultValue: () => SlotsAvailability.ALL,
  },
  limitTimeSlots: {
    getDefaultValue: () => true,
  },
  maxTimeSlotsPerDay: {
    getDefaultValue: () => 6,
  },
  scrollBetweenDays: {
    getDefaultValue: () => true,
  },
  itemsBeforeLoadMore: {
    getDefaultValue: () => 10,
  },
  selectedLocations: {
    getDefaultValue: () => [],
  },
  selectedCategories: {
    getDefaultValue: () => [],
  },
  selectedServices: {
    getDefaultValue: () => [],
  },
  timeSlotsPerDay: {
    getDefaultValue: () => 10,
  },
  titleText: {
    getDefaultValue: ({ t }) => t('app.settings.defaults.titleText'),
  },
  titleTagName: {
    getDefaultValue: () => AccessibilityHtmlTags.SecondaryHeading,
  },
  spotsLeftVisibility: {
    getDefaultValue: () => true,
  },

  noSpotsLeft: {
    getDefaultValue: ({ t }) =>
      t('daily-agenda.settings.text.button.no-availability-text.placeholder'),
  },
  spotsLeftFormat: {
    getDefaultValue: ({ t }) =>
      t('daily-agenda.settings.text.table-slots.availability.placeholder'),
  },

  serviceLabel: {
    getDefaultValue: ({ t }) =>
      t(
        'daily-agenda.settings.text.filters-and-preferences.service-label.placeholder',
      ),
  },
  staffMemberLabel: {
    getDefaultValue: ({ t }) =>
      t(
        'daily-agenda.settings.text.filters-and-preferences.staff-member-label.placeholder',
      ),
  },
  locationLabel: {
    getDefaultValue: ({ t }) =>
      t(
        'daily-agenda.settings.text.filters-and-preferences.location-label.placeholder',
      ),
  },
  timeSlotsBeforeLoadMore: {
    getDefaultValue: () => 12,
  },
  toEarlyToBookIndication: {
    getDefaultValue: ({ t }) =>
      t('daily-agenda.settings.text.button.to-early-to-book.placeholder'),
  },
  toLateToBookIndication: {
    getDefaultValue: ({ t }) =>
      t('daily-agenda.settings.text.button.to-late-to-book.placeholder'),
  },
  bookOnlineDisabledIndication: {
    getDefaultValue: ({ t }) => t('app.settings.defaults.button.more-info'),
  },
  joinWaitlistButton: {
    getDefaultValue: ({ t }) =>
      t('daily-agenda.settings.text.button.wait-list.placeholder'),
  },
  pendingApprovalButton: {
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.pending-approval.text'),
  },
  bookButtonText: {
    getDefaultValue: ({ t }) =>
      t('daily-agenda.settings.text.button.book-button.placeholder'),
  },
};

export default createSettingsParams<ISettingsParams>(settingsParamsDefinitions);

import {
  formatTimezone,
  getLocalTimezone,
} from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';
import { FilterOption } from '../../../../types/types';
import { WidgetViewModelFactory } from '../viewModel';
import { ViewModelSubscription } from '../initViewModelSubscriptions';

export type TimeZoneViewModel = {
  label: string;
  options: FilterOption[];
};

export const createTimeZoneViewModel: WidgetViewModelFactory<
  TimeZoneViewModel
> = ({ flowAPI, state: { selectedTimezone, languageSettings } }) => {
  const date = new Date();
  const locale = languageSettings.dateRegionalSettingsLocale;
  const selectableTimezones: string[] = [
    getLocalTimezone(),
    flowAPI.controllerConfig.wixCodeApi.site.timezone!,
  ];
  const uniqueSelectableTimezoneOptions = Array.from(
    new Set(selectableTimezones),
  );
  const formattedTimezone = formatTimezone({
    date,
    locale,
    timezone: selectedTimezone,
  });

  const options: FilterOption[] = uniqueSelectableTimezoneOptions.map(
    (timezone) => ({
      id: timezone,
      value: formatTimezone({ date, locale, timezone }),
      selected: timezone === selectedTimezone,
    }),
  );

  return {
    label: formattedTimezone,
    options,
  };
};

export const subscribeToTimeZoneViewModel: ViewModelSubscription = ({
  subscribe,
  setViewModel,
  flowAPI,
  getState,
}) =>
  subscribe(
    ({ selectedTimezone }) => ({ selectedTimezone }),
    ({ selectedTimezone }) => {
      const timeZoneViewModel = createTimeZoneViewModel({
        flowAPI,
        state: { ...getState(), selectedTimezone },
      });
      setViewModel({
        timeZoneViewModel,
      });
    },
  );
